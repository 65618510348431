import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { ACSS, Box, H3, H5, P } from "@upsolve/ui";

import BlobAttorney from "../../../static/images/blob-attorney.svg";
import FinancialJourneyScape from "../../../static/images/financial-journey-scape.svg";
import ImageInContext from "../Media/ImageInContext";
import UserFacesRow from "../ProofBuilders/UserFacesRow";
import { MyUpsolveButtonLink } from "../Links/MyUpsolveLink";
import trackComponentAction from "../analytics/trackComponentAction";
import trackComponentImpression from "../analytics/trackComponentImpression";
import upsolveStats from "../../../content/onBuild/upsolveStats.json";
import withViewportWatch from "../analytics/withViewportWatch";
import { CTATarget, getCTARenderedCopy, getCTATrackingTarget, getCTAURL } from "./getCTAData";
import { ProductSelectorModal } from "../Forms/ProductSelectorModal";

const trackingProps = {
  componentName: "CTASideBySideOfferings",
  componentVersion: "1",
};

type Props = {
  isInViewport: boolean;
  trackingPageContext: any;
  stopWatcher: () => void;
  ctaTarget?: CTATarget;
};
/**
 * CTASideBySideOfferings
 * - v0: 2 blobs - one for my.upsolve and one for attorney referral
 * - v1: replace my.upsolve blob with phone demo image
 *
 * @component
 * @version 1
 */
const CTASideBySideOfferings = (props: Props) => {
  const { ctaTarget = CTATarget.SCREENER } = props;
  const [impressionTracked, setImpressionTracked] = useState(false);
  const [isTriageOpen, setIsTriageOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query PhoneDemoQuerySideBySideCTA {
      imagePhoneDemoShort: file(base: { eq: "phone-demo-short.png" }) {
        childImageSharp {
          fluid(
            quality: 90
            maxHeight: 200
            traceSVG: { color: "#333", blackOnWhite: true, turnPolicy: TURNPOLICY_MINORITY, threshold: 50 }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  if (props.isInViewport && !impressionTracked) {
    setImpressionTracked(true);
    props.stopWatcher();
    trackComponentImpression({
      ...trackingProps,
      pageContext: props.trackingPageContext,
    });
  }

  const ScreenerOffering = () => {
    return (
      <div className="side-by-side__option">
        <div className="option__body">
          <div className="option__blob ledge">
            <ImageInContext alt="Upsolve app demo" fluid={data.imagePhoneDemoShort.childImageSharp.fluid} />
          </div>
          <H3>{getCTARenderedCopy(ctaTarget).title}</H3>
          <P>{getCTARenderedCopy(ctaTarget).description}</P>

          <MyUpsolveButtonLink
            size="md"
            href={getCTAURL(ctaTarget)}
            onClick={() => {
              trackComponentAction({
                ...trackingProps,
                actionId: `ctaSideBySide.${getCTATrackingTarget(ctaTarget)}`,
                actionMethod: "click",
                pageContext: props.trackingPageContext,
              });
            }}
          >
            <b>{getCTARenderedCopy(ctaTarget).buttonText}</b>
          </MyUpsolveButtonLink>
        </div>
        <div className="option__count ledge">
          <b>☆ {Number(upsolveStats.filings.total).toLocaleString()} families</b> have filed with Upsolve! ☆
        </div>
      </div>
    );
  };
  const TriageOffering = () => {
    return (
      <div className="side-by-side__option">
        <div className="option__body">
          <div className="option__blob ledge">
            <img alt="Upsolve app demo" src="/images/debt_triage_image.png" height="200px" />
          </div>
          <H3>{getCTARenderedCopy(ctaTarget).title}</H3>
          <P>{getCTARenderedCopy(ctaTarget).description}</P>

          <MyUpsolveButtonLink
            size="md"
            href={getCTAURL(ctaTarget)}
            onClick={() => {
              trackComponentAction({
                ...trackingProps,
                actionId: `ctaSideBySide.${getCTATrackingTarget(ctaTarget)}`,
                actionMethod: "click",
                pageContext: props.trackingPageContext,
              });
              setIsTriageOpen(true);
            }}
          >
            <b>{getCTARenderedCopy(ctaTarget).buttonText}</b>
          </MyUpsolveButtonLink>
        </div>
      </div>
    );
  };

  return (
    <CTASideBySide data-test-id="ctaSideBySideOfferings">
      <div className="side-by-side__header">
        <Box display="flex" width="100%" justifyContent="center">
          <UserFacesRow faceCount={6} />
        </Box>
        <H3 as="p">It's easy to get debt help</H3>
        <H5 as="p">Choose one of the options below to get assistance with your debt:</H5>
      </div>
      <div className="side-by-side__options">
        {ctaTarget === CTATarget.DEBT_TRIAGE ? <TriageOffering /> : <ScreenerOffering />}
        <div className="side-by-side__divider">
          <span>or</span>
        </div>
        <div className="side-by-side__option">
          <div className="option__body">
            <div className="option__blob attorney">
              <BlobAttorney />
            </div>
            <H3>Private Attorney</H3>
            <P>Get a free evaluation from an independent law firm.</P>
            <MyUpsolveButtonLink
              size="md"
              href={`${UPSOLVE_FILER_URL}/referral`}
              onClick={() =>
                trackComponentAction({
                  ...trackingProps,
                  actionId: "ctaSideBySide.findALawyerNavigation",
                  actionMethod: "click",
                  pageContext: props.trackingPageContext,
                })
              }
            >
              <b>Find Attorney</b>
            </MyUpsolveButtonLink>
          </div>
        </div>
      </div>
      <div className="landscape">
        <FinancialJourneyScape />
      </div>
      {isTriageOpen && (
        <ProductSelectorModal trackingPageContext={props.trackingPageContext} onHide={() => setIsTriageOpen(false)} />
      )}
    </CTASideBySide>
  );
};

const CTASideBySide = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  div.side-by-side__header {
    width: 100%;
    padding: 1.5em 1em 1em;
    text-align: center;
    p {
      color: ${(props) => props.theme.colors.brand[500]};
      margin: 0.25em 0;
    }
  }
  div.side-by-side__options {
    display: flex;
    padding: 0.5em 1.5em 1.5em;
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      flex-direction: column;
    }
  }
  div.side-by-side__option {
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.white[900]};
    border-radius: 1em;
    margin: 2em;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 25, 0.1);
    h3,
    h5 {
      color: ${(props) => props.theme.colors.brand[500]};
    }
    p {
      padding: 0 1em 0.25em;
      a {
        text-decoration: underline;
      }
    }
    button {
      margin: 0.5em;
    }
    .option__body {
      padding: 1.5em;

      a,
      button {
        margin: 0 0 0.5em;
      }
      span {
        ${ACSS}
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
      button {
        width: 100%;
      }
    }
    .option__blob {
      height: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .gatsby-image-wrapper {
        width: 100%;
      }
      svg {
        height: 200px;
        max-height: 200px;
        width: auto;
        fill: ${(props) => props.theme.colors.brand[500]};
      }
      &.attorney {
        svg {
          fill: ${(props) => props.theme.colors.brand[500]};
        }
      }
    }
    .option__count {
      background: ${(props) => props.theme.colors.white[700]};
      color: ${(props) => props.theme.colors.gray[600]};
      width: 100%;
      padding: 1em 0;
      font-size: 0.8em;
      font-weight: 500;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      width: 100%;
      margin: 0;
      button {
        width: 100%;
      }
    }
  }
  div.side-by-side__divider {
    width: 1px;
    background: ${(props) => props.theme.colors.white[300]};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background: ${(props) => props.theme.colors.gray[700]};
      color: ${(props) => props.theme.colors.white[900]};
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      justify-content: center;
      height: 1px;
      width: 100%;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
  }

  .landscape {
    padding: 0 1em 1em;
    text-align: center;
    svg {
      height: 240px;
      opacity: 0.25;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      svg {
        max-width: 100%;
        height: 100px;
      }
    }
  }
`;

export default withViewportWatch(CTASideBySideOfferings);
