import { get, shuffle } from "lodash";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

import ImageInContext from "../Media/ImageInContext";

const UserFacesRow = (props) => (
  <StaticQuery
    query={graphql`
      query UserFacesRowQuery {
        allContentfulFreshStartDiary(limit: 10) {
          nodes {
            id
            filerImage {
              altText
              image {
                file {
                  url
                }
                fluid(maxWidth: 56, maxHeight: 56) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const stories = get(data, "allContentfulFreshStartDiary.nodes", []).slice(0, props.faceCount || 5);
      return (
        <UserFacesRowWrapper>
          <div className="filers">
            {stories.map((story, index) => (
              <span key={index} className={`filer-${index}`}>
                <ImageInContext
                  className={`filer-${index}`}
                  alt={`${story.filerImage.altText} ${index + 1}`}
                  fluid={story.filerImage.image.fluid}
                  src={story.filerImage.image.file.url}
                />
              </span>
            ))}
          </div>
        </UserFacesRowWrapper>
      );
    }}
  />
);

const UserFacesRowWrapper = styled.span`
  &,
  div.filers {
    display: flex;
    align-items: center;
  }
  span[class^="filer-"] {
    height: 28px;
    width: 28px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: -4px;
    img {
      width: 100%;
      min-height: 100%;
      min-width: 100%;
      transform: scale(1.1);
    }
  }
  @media (max-width: 45em) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default UserFacesRow;
